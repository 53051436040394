import React, { FC, useMemo } from 'react';

import { handleArticleCustomListRenderer } from './helpers';
import { IPropsArticleBodyCustomList } from './models';

import './ArticleBodyCustomList.scss';

const ArticleBodyCustomList: FC<IPropsArticleBodyCustomList> = ({ data, poolProductsByLink }) => {
  const elements = useMemo(() => handleArticleCustomListRenderer(), [data.items]);

  return (
    <ul data-testid="ArticleBodyCustomList" className="article-body-custom-list">
      {data?.items.map((listItem, index) => {
        const keyId = `${listItem.structure}_${index}`;

        return listItem?.structure && elements[listItem.structure] ? (
          <li key={keyId} className="article-body-custom-list__item">
            {elements[listItem.structure](
              {
                data: listItem,
                poolProductsByLink,
              },
              keyId
            )}
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default ArticleBodyCustomList;

import { useEffect } from 'react';

import { IHookAddClassToHtml } from './models';

const useAddClassToHtml = ({ className }: IHookAddClassToHtml) => {
  useEffect(() => {
    document.documentElement.classList.add(className);

    return () => {
      document.documentElement.classList.remove(className);
    };
  }, [className]);
};

export default useAddClassToHtml;

import React, { FC, Fragment, useMemo } from 'react';
import { UmbracoProductCard } from '@shared/types';
import useScreenRecognition from 'hooks/useScreenRecognition';

import ProductCard from 'components/cards/ProductCard';
import Typography from 'components/elements/Typography';
import SecondaryFeatures from 'components/product/SecondaryFeatures';
import { matchUrlsToData } from 'utils/sorting';

import { IPropsArticleBodyProductsList } from './models';

import './ArticleBodyProductsList.scss';

const ArticleBodyProductsList: FC<IPropsArticleBodyProductsList> = ({
  data,
  poolProductsByLink,
  withSecondaryFeatures,
}) => {
  const { isNotMobile } = useScreenRecognition();
  const itemsToRender = useMemo(
    () => matchUrlsToData(poolProductsByLink, data.selectedProductsLinks),
    [data, poolProductsByLink]
  );

  return (
    <div data-testid="ArticleBodyProductsList" className="article-body-products-list">
      {data?.productsListTitle?.[0] ? (
        <Typography data={data.productsListTitle} customClass="article-body-products-list__title" />
      ) : null}
      <div className="article-body-products-list__wrapper">
        {itemsToRender?.map((product: UmbracoProductCard.IStructure) => (
          <Fragment key={product.url}>
            <ProductCard
              data={product}
              variant={isNotMobile ? 'horizontal' : 'vertical'}
              key={product.url}
            />
            {withSecondaryFeatures && product?.secondaryFeatures?.[0] ? (
              <SecondaryFeatures data={product.secondaryFeatures} />
            ) : null}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ArticleBodyProductsList;

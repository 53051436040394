import React, { FC } from 'react';

import DynamicImage from 'components/helpers/DynamicImage';

import { IPropsArticleBodyImage } from './models';

import './ArticleBodyImage.scss';

const ArticleBodyImage: FC<IPropsArticleBodyImage> = ({ data }) => (
  <div data-testid="ArticleBodyImage" className="article-body-image">
    <DynamicImage image={data.bodyImage} />
  </div>
);

export default ArticleBodyImage;

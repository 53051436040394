import React, { FC, useCallback } from 'react';

import { addURLAnchor, scrollToTarget } from 'utils/browser';
import { textNoSpaces } from 'utils/stringUtils/stringUtils';

import Button from '../Button';
import { IPropsScrollButton } from './models';

import './ScrollButton.scss';

const ScrollButton: FC<IPropsScrollButton> = ({ navigationName, scrollOffset, link }) => {
  const updateNavigationName = textNoSpaces(navigationName, '_');

  const handleScroll = useCallback(() => {
    scrollToTarget(updateNavigationName, scrollOffset || 0);

    if (link) {
      addURLAnchor(updateNavigationName);
    }
  }, []);

  return (
    <div className="scroll-button" data-testid="ScrollButton">
      <Button
        data={[{ ariaLabel: navigationName, variant: 'text-link' }]}
        clickHandler={handleScroll}
        className="scroll-button__button"
      >
        {navigationName}
      </Button>
    </div>
  );
};

export default ScrollButton;

import React, { FC, Fragment, useMemo } from 'react';

import { handleArticleBodyRenderer } from './helpers';
import { IPropsArticleBody } from './models';

import './ArticleBody.scss';

const ArticleBody: FC<IPropsArticleBody> = ({ articleBody, poolProductsByLink }) => {
  const elements = useMemo(() => handleArticleBodyRenderer(), [articleBody]);

  return (
    <div data-testid="ArticleBody" className="article-body">
      {articleBody.map((bodyItem, index) => {
        const keyId = `${bodyItem.structure}_${index}`;

        return bodyItem?.structure && elements[bodyItem.structure] ? (
          <Fragment key={keyId}>
            {elements[bodyItem.structure](
              {
                data: { ...bodyItem },
                poolProductsByLink,
              },
              keyId
            )}
          </Fragment>
        ) : null;
      })}
    </div>
  );
};

export default ArticleBody;

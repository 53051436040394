import React from 'react';

import ArticleBodyProductsList from 'components/article/ArticleBodyProductsList';
import { IPropsArticleBodyProductsList } from 'components/article/ArticleBodyProductsList/models';
import ArticleTextSection from 'components/article/ArticleTextSection';
import { IPropsArticleTextSection } from 'components/article/ArticleTextSection/models';
import ScrollWrapper from 'components/helpers/ScrollWrapper';

export const handleArticleCustomListRenderer = () => ({
  // eslint-disable-next-line react/no-unstable-nested-components
  'Article Text Section': ({ data }: IPropsArticleTextSection) => (
    <ScrollWrapper name={data.navigationName || ''}>
      <ArticleTextSection
        data={{
          structure: data.structure,
          sectionTitle: data.sectionTitle,
          sectionSubTitle: data.sectionSubTitle,
          sectionBody: data.sectionBody,
          navigationName: data.navigationName,
        }}
      />
    </ScrollWrapper>
  ),
  // eslint-disable-next-line react/no-unstable-nested-components
  'Article Body Products List': ({ data, poolProductsByLink }: IPropsArticleBodyProductsList) => (
    <ScrollWrapper name={data.navigationName || ''}>
      <ArticleBodyProductsList
        data={{
          structure: data.structure,
          productsListTitle: data.productsListTitle,
          selectedProductsLinks: data.selectedProductsLinks,
          navigationName: data.navigationName,
        }}
        poolProductsByLink={poolProductsByLink}
        withSecondaryFeatures
      />
    </ScrollWrapper>
  ),
});
